import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Oylik tushum sarhisobi")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('vue-apex-charts',{staticClass:"h-full d-flex align-center",attrs:{"id":"chart-sales-overview","options":_vm.chartOptions,"series":_vm.chartData}})],1),_c(VCol,{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"rounded":"","size":"40"}},[_c(VIcon,{attrs:{"size":"30","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyUsd)+" ")])],1),_c('div',{staticClass:"ms-4 d-flex flex-column"},[_c('p',{staticClass:"text--primary mb-0 text-base"},[_vm._v(" Joriy oy olinishi kerak ")]),_c('span',{staticClass:"text--primary font-weight-semibold text-xl"},[_vm._v("$86,400")])])],1),_c(VDivider,{staticClass:"my-6"}),_c('table',{staticClass:"sales-overview-stats-table"},[_c('tr',[_c('td',[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"stats-dot primary d-inline-block rounded-circle me-2"}),_c('span',[_vm._v("Tushum")])]),_c('span',{staticClass:"text-base text--primary font-weight-semibold ms-4"},[_vm._v("$1,840")])]),_c('td',[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"stats-dot secondary d-inline-block rounded-circle me-2"}),_c('span',[_vm._v("Olinishi zarur")])]),_c('span',{staticClass:"text-base text--primary font-weight-semibold ms-4"},[_vm._v("$46,054")])])]),_c('tr',[_c('td'),_c('td')])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }